.rv-account-form-section {
    padding: 120px 0;
    .auth-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        border: 1px solid #00000040;
        padding: 50px 0;
        width: 50%;
        border-radius: 20px;
        @include md {
            width: 80%;
        }
        @include xs {
            width: 95%;
        }
        form {
            padding: 40px 40px 0;
            display: grid;
            grid-gap: 15px;
            gap: 15px;
            border-radius: 3px;
            width: 100%;
            @include xxs {
                padding: 25px 15px 0;
            }
            input#login-password, input#login-username, input#register-email, input#register-password, input#register-username {
                width: 100%;
                border: 1px solid #00000040;
                border-radius: 3px;
                height: 45px;
                padding: 0 20px;
            }
            input#sign-in-checkbox {
                margin-right: 5px;
            }
            .stay-sign-in {
                display: flex;
                align-items: center;
                @include xxs {
                    font-size: 13px;
                }
            }
            a.password-recovery-btn {
                color: #509e0f;
                @include xxs {
                    font-size: 13px;
                }
            }
            button.rv-1-banner-btn.single-form-btn {
                padding: 0 80px;
                margin-top: 20px;
                justify-self: center;
                border-radius: 6px;
                background-color: #222;
                height: 45px;
                line-height: 43px;
                font-size: 16px;
                color: #fff;
                border: 1px solid #222;
                &:hover {
                    background-color: transparent;
                    color: var(--black);
                    border-color: #509e0f;
                }
            }
            .alternative-auth {
                width: 100%;
                text-align: end;
                a {
                    color: #509e0f;
                }
            }
        }
        .other-option {
            text-align: center;
            padding-top: 20px;
            p {
                font-size: 16px;
                line-height: 100%;
                color: #515151;
                margin-top: -2px;
                margin-bottom: 17px;
            }
            a {
                text-align: center;
                width: 45px;
                height: 45px;
                line-height: 45px;
                border-radius: 3px;
                font-size: 22px;
                color: #222;
                &:hover {
                    color: #794AFF;
                }
            }
        }
    }
}