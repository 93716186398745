/* ----- 47. ABOUT PAGE ----- */
.rv-inner-about-btn {

    .txt,
    .icon {
        border-color: #509e0f;
        background-color: #509e0f;
    }
}

.rv-1-section-heading-2 .rv-1-section-heading__right .rv-inner-about-btn:hover {
    border-color: #509e0f;
    background-color: #509e0f;
}

.rv-inner-slider-nav button:hover {
    border-color: #509e0f;
    background-color: #509e0f;
}