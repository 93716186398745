/* ----- 45. SERVICE DETAILS PAGE ----- */
.rv-service-details {
    &__row {
        @include md {
            --bs-gutter-y: 50px;
        }
    }

    &__img {
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 33px;

        @include lg {
            margin-bottom: 23px;
        }

        @include md {
            margin-bottom: 18px;
        }

        img {
            width: 100%;
            aspect-ratio: 61 / 32;
        }
    }

    &__title {
        color: var(--black);
        font-size: 34px;
        font-weight: 700;
        margin-bottom: 15px;

        @include xl {
            font-size: 32px;
        }

        @include lg {
            font-size: 30px;
        }

        @include sm {
            font-size: 28px;
        }

        @include xs {
            font-size: 26px;
        }

        @include xxs {
            font-size: 24px;
        }
    }

    &__descr {
        color: rgb(8 8 33 / 60%);
        font-family: Roboto;
        margin-bottom: 26px;

        @include xs {
            margin-bottom: 20px;
        }
    }

    &__blockquote.blockquote {
        border-radius: 10px;

        @include xl {
            margin: 34px 0 40px;
            padding: 98px 90px 45px;
        }

        @include lg {
            margin: 30px 0 36px;
            padding: 88px 30px 35px;
        }

        @include xs {
            margin: 20px 0 26px;
            padding: 88px 30px 35px;
        }
    }

    &__cards {
        margin-bottom: 73px;

        @include xl {
            margin-bottom: 63px;
        }

        @include lg {
            margin-bottom: 53px;
        }

        @include md {
            margin-bottom: 43px;
        }

        .row {
            @include xl {
                --bs-gutter-x: 25px;
            }

            @include lg {
                --bs-gutter-x: 20px;
            }
        }
    }

    &-card {
        border: 1px solid rgba(5, 5, 5, 0.14);
        border-radius: 20px;
        padding: 54px 44px;

        @include xl {
            padding: 40px 34px;
        }

        @include lg {
            padding: 30px;
        }

        @include sm {
            padding: 20px;
        }

        &__tag {
            border: 1px solid rgba(5, 5, 5, .14);
            border-radius: 6px;
            padding: 2px 14px 3px;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 60px;

            @include xl {
                margin-bottom: 40px;
            }

            @include sm {
                margin-bottom: 30px;
            }
        }

        &__icon {
            margin-bottom: 38px;

            @include xl {
                margin-bottom: 28px;
            }

            @include sm {
                margin-bottom: 23px;
            }

            img {
                width: 110px;
                height: 110px;
                object-fit: scale-down;

                @include lg {
                    width: 100px;
                    height: 100px;
                }

                @include md {
                    width: 90px;
                    height: 90px;
                }
            }
        }

        &__descr {
            color: #838383;
            font-size: 20px;
            line-height: 150%;
            margin-bottom: 42px;

            @include xl {
                font-size: 18px;
                margin-bottom: 32px;
            }

            @include sm {
                margin-bottom: 27px;
            }

            .highlight {
                color: var(--black);
            }
        }

        &__btn {
            background-color: #509e0f;
            border-color: #509e0f;
            border-radius: 10px;
            padding: 0 42px;
            font-family: var(--inter);
            font-weight: 600;
            line-height: 48px;

            @include lg {
                line-height: 43px;
                padding: 0 32px;
            }

            @include sm {
                line-height: 38px;
                padding: 0 27px;
            }

            @include xs {
                line-height: 33px;
                padding: 0 22px;
            }
        }
    }

    &-sidebar {
        &-widget {
            border-radius: 10px;
            background-color: #EEF1F6;
            padding: 30px;

            @include xl {
                padding: 25px;
            }

            @include lg {
                padding: 22px 20px;
            }

            &:not(:last-child) {
                margin-bottom: 30px;

                @include xl {
                    margin-bottom: 25px;
                }
            }
        }

        &__search-form {
            box-shadow: 0px 1px 2px 0px rgba(13, 18, 30, 0.08);
            background-color: var(--white-f);
            border-radius: 10px;
            display: flex;
            padding: 5px;

            input {
                height: 47px;
                padding: 0 20px 0 18px;
                width: 100%;

                @include lg {
                    height: 40px;
                    padding: 0 10px 0 8px;
                }
            }

            button {
                background-color: #509e0f;
                width: 47px;
                height: 47px;
                border-radius: 6px;
                flex-shrink: 0;
                color: var(--white-f);
                border: 1px solid #509e0f;

                @include lg {
                    width: 40px;
                    height: 40px;
                }

                &:hover {
                    background-color: transparent;
                    color: #509e0f;
                }
            }
        }

        &-categories {
            li:not(:last-child) {
                margin-bottom: 6px;
            }

            a {
                border-radius: 10px;
                box-shadow: 0px 1px 2px 0px rgba(13, 18, 30, 0.08);
                background-color: var(--white-f);
                padding: 16.25px 24px;
                color: #080821;
                font-size: 17px;
                font-weight: 500;
                width: 100%;

                @include lg {
                    padding: 12.25px 15px;
                    font-size: 16px;
                }

                &:hover {
                    color: var(--white-f);
                    background-color: var(--black);
                }
            }
        }
    }
}

.rv-accordion {
    &-item {
        background-color: var(--white);
        border-bottom: 1px solid rgba(10, 37, 64, 0.10);
        padding: 25px 0;

        @include lg {
            padding: 20px 0;
        }

        @include md {
            padding: 15px 0;
        }

        &:first-child {
            @include lg {
                padding-top: 15px;
            }
        }

        &-header {
            display: grid;
            grid-template-columns: auto 20px;
            align-items: center;
            cursor: pointer;
            gap: 10px;
        }

        &-title {
            margin-bottom: 0;
            color: #0A2540;
            font-size: 18px;
            font-weight: 600;
            line-height: 133.333%;

            @include xxs {
                font-size: 16px;
            }
        }

        &-expand-icon {
            width: 24px;
            height: 24px;
            position: relative;
            display: block;
            justify-self: end;
            background-color: rgb(10, 37, 64, 0.1);
            border-radius: 50%;
            flex-shrink: 0;

            &::before,
            &::after {
                content: "";
                position: absolute;
                width: 8px;
                height: 2px;
                background-color: var(--black);
                transition: 0.4s ease;
                inset: 0;
                margin: auto;
            }

            &::after {
                transform: rotate(90deg);
            }
        }

        &-body {
            margin-bottom: 0;
            line-height: 0;
            overflow: hidden;
            transition: 0.4s ease;
            opacity: 0;
            color: rgba(10, 37, 64, 0.60);
            font-size: 15px;
            font-weight: 500;
        }

        &.open {
            .rv-accordion-item {
                &-expand-icon::after {
                    transform: rotate(180deg);
                }

                &-body {
                    padding-top: 23px;
                    opacity: 1;
                    line-height: 1.6;

                    @include lg {
                        padding-top: 18px;
                    }

                    @include xxs {
                        padding-top: 13px;
                    }
                }
            }
        }
    }
}