/* ----- 37. Blogs Page ----- */

.rv-inner-blogs {
    &__row {
        position: relative;
        column-count: 3;
        column-gap: 30px;

        @include lg {
            column-gap: 20px;
        }

        @include md {
            column-count: 2;
        }

        @include sm {
            column-count: 1;
        }
    }

    .rv-1-blog {
        break-inside: avoid;

        @include sm {
            width: 75%;
            margin-inline: auto;
        }

        @include xxs {
            width: 100%;
        }

        &:not(:first-child) {
            margin-top: 30px;

            @include lg {
                margin-top: 20px;
            }
        }

        &__title {
            a:hover {
                color: #509e0f;
            }
        }

        &__btn {
            &:hover {
                i {
                    background-color: #509e0f;
                    border-color: #509e0f;
                }
            }
        }
    }
}

.rv-inner-blog {
    .rv-1-blog__img img {
        height: auto;
        aspect-ratio: 412 / 300;
    }

    &--big {
        .rv-1-blog__img {
            img {
                // height: 350px;
                aspect-ratio: 412 / 350;
            }
        }
    }

    &--small {
        .rv-1-blog__img {
            img {
                // height: 250px;
                aspect-ratio: 412 / 250;
            }
        }
    }
}