/* ----- 32. Support ----- */
.rv-10-support {
    max-width: calc(100% - 200px);
    margin: auto;
    background: url(../../img/rv-10-support-bg.jpg) no-repeat center center;
    background-size: cover;
    position: relative;
    z-index: 1;
    border-radius: 40px;
    overflow: hidden;

    @media screen and (max-width: 1654px) {
        max-width: 100%;
    }

    &__row {
        @include xl {
            --bs-gutter-x: 60px;
        }

        @include lg {
            --bs-gutter-x: 40px;
        }
    }

    &::before {
        content: "";
        position: absolute;
        inset: 0;
        background-color: rgb(5 5 5 / 70%);
        z-index: -1;
    }

    &-form-container {
        background-color: var(--white-f);
        padding: 70px 60px;
        border-radius: 20px;
        text-align: center;

        @include lg {
            padding: 50px 40px;
        }

        @include xs {
            padding: 40px 30px;
        }

        @include xxs {
            padding: 25px 20px;
        }
    }

    &-form {
        &__title {
            color: #050505;
            font-family: var(--mulish);
            font-size: 26px;
            font-weight: 800;
            margin-bottom: 16px;
        }

        &__payment-methods {
            margin-bottom: 40px;
        }

        &__field {
            border-radius: 6px;
            border: 1px solid rgba(5, 5, 5, 0.14);
            display: flex;
            margin-bottom: 14px;

            span.icon {
                width: 60px;
                background-color: #EFC329;
                border-radius: 5px;
                flex-shrink: 0;
                color: #050505;
                font-size: 34px;
                font-weight: 700;
                line-height: 60px;

                @include xl {
                    width: 54px;
                    line-height: 54px;
                    font-size: 30px;
                }

                @include lg {
                    width: 50px;
                    line-height: 50px;
                    font-size: 28px;
                }
            }

            input {
                width: 100%;
                padding: 4.5px 20px;
                text-align: center;
                color: #050505;
                font-family: var(--mulish);
                font-size: 34px;
                font-weight: 700;

                @include xl {
                    font-size: 30px;
                }

                @include lg {
                    padding: 2.5px 20px;
                    font-size: 28px;
                }

                &::placeholder {
                    opacity: 0.5;
                }
            }
        }

        &__suggests {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: 7.9px;
            margin-bottom: 48px;

            @include lg {
                margin-bottom: 38px;
            }

            button {
                border-radius: 4px;
                background: #EFC329;
                color: #050505;
                font-family: var(--mulish);
                font-size: 18px;
                font-weight: 700;
                line-height: 1.7;
                padding: 0 14px;

                &:hover {
                    background-color: var(--black);
                    color: #EFC329;
                }
            }
        }

        .rv-10-def-btn {
            background-color: var(--black);
            border-color: var(--black);
            color: var(--white-f);
            width: 100%;

            &:hover {
                background-color: #EFC329;
                border-color: #EFC329;
                color: var(--black);
            }
        }
    }

    .rv-10-about__descr {
        margin-bottom: 27px;
    }

    .rv-10-stats__bottom {
        margin-bottom: 18px;
        gap: 25px;
    }

    &__txt {
        .rv-10-section__sub-title {
            color: var(--white-f);

            .styled {
                color: var(--black);
            }
        }

        .rv-10-section__title {
            color: var(--white-f);
            margin-bottom: 20px;
        }

        .rv-10-about__descr {
            color: rgb(255 255 255 / 60%);
            margin-bottom: 48px;
        }

        .rv-10-def-btn {
            background-color: var(--white-f);
            border-color: var(--white-f);
            color: var(--black);

            &:hover {
                background-color: var(--black);
                border-color: var(--black);
                color: var(--white-f);
            }
        }
    }
}